/*
	scripts.js

	License: GNU General Public License v3.0
	License URI: http://www.gnu.org/licenses/gpl-3.0.html



*/
/* load
/* ------------------------------------ */

jQuery(window).load(function() {

	/*  loading
	/* ------------------------------------ */
	jQuery('#loading').fadeOut(1000);
	
	});
	
	
	$(function() {
	
		$('.button').mousedown(function (e) {
		var target = e.target;
		var rect = target.getBoundingClientRect();
		var ripple = target.querySelector('.ripple');
		$(ripple).remove();
		ripple = document.createElement('span');
		ripple.className = 'ripple';
		ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
		target.appendChild(ripple);
		var top = e.pageY - rect.top - ripple.offsetHeight / 2 -  document.body.scrollTop;
		var left = e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft;
		ripple.style.top = top + 'px';
		ripple.style.left = left + 'px';
		return false;
	});
	
	
	
	
	
	
	/* addClass
	/* ------------------------------------ */
				jQuery("#primary img").addClass("wow fadeIn");
				jQuery(".custom-voice img").removeClass("wow fadeIn");
				jQuery(".customer-voice img").removeClass("wow fadeIn");
				jQuery(".wp-block-cover img").removeClass("wow fadeIn");
	
	
	
	/* スムーススクロール
	/* ------------------------------------ */
		  jQuery('a[href^="#"]').click(function() {// # クリック処理
		  var speed = 700; //スクロール速度ミリ秒
		  var href= jQuery(this).attr("href"); // アンカーの値取
		  // 移動先を取得
		  var target = jQuery(href == "#" || href == "" ? 'html' : href);
		  var position = target.offset().top;// 移動先を数値で取得
		  // スムーススクロール
		  jQuery('body,html').animate({scrollTop:position}, speed, 'swing');
		  return false;
	   });
	
	/* fix smart icon
	/* ------------------------------------ */
	jQuery(window).scroll(function () {
	var ScrTop = jQuery(document).scrollTop();
	if (ScrTop > 180) {
	jQuery('.footer_fixed_banner').css({'bottom' : '0px','opacity':'1.0'});
	}
	if (ScrTop < 180) {
	jQuery('.footer_fixed_banner').css({'bottom' : '-100px','opacity':'0.0'});
	}
	});
	
	
	jQuery('.footer_fixed_close a').click(function() {// # クリック処理
	jQuery('.footer_fixed_banner').css({'display' : 'none'});
	});
	
	
		/*アコーディオン*/
		//  var Accordion = function(el, multiple) {
		//     this.el = el || {};
		//     this.multiple = multiple || false;
		//
		//     // Variables privadas
		//     var links = this.el.find('.link');
		//     // Evento
		//     links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown)
		// }
		//
		// Accordion.prototype.dropdown = function(e) {
		//     var $el = e.data.el;
		//         $this = $(this),
		//         $next = $this.next();
		//
		//     $next.slideToggle();
		//     $this.parent().toggleClass('open');
		//
		//     if (!e.data.multiple) {
		//         $el.find('.submenu').not($next).slideUp().parent().removeClass('open');
		//     }
		// };
		//
		// var accordion = new Accordion($('#accordion'), false);
	
		/* アコーディオン（作り直し） */
		$(function(){
			$('.accordion').each(function(){
	
				var ul = $(this);
				var allFaq = ul.find('div.link');
				var allAnswer = ul.find('div.faq_answer');
				var allLi = ul.find('li');
	
				allAnswer.hide();
				allFaq.css('cursor', 'pointer');
	
	
	
				   allFaq.click(function(){
	
					   //console.log("click");
	
					var faq = $(this);
					var answer = faq.next();
					var li = faq.parent();
	
	
					if($(this).hasClass("accordion_open")){
						$(this).removeClass("accordion_open");
						$(this).addClass("accordion_close");
						//answer.hide(500);
					  }else{
	
						  $(this).removeClass("accordion_close");
						  $(this).addClass("accordion_open");
	
						  //answer.show(500);
					  }
	
	
	
					/*
					allAnswer.hide(300);
					allLi.removeClass('open');
					li.addClass('open');
					answer.show(500);
					allFaq.css('cursor', 'pointer');
					faq.css('cursor', 'default');
					*/
				});
	
			});
		});
	
	
	
	
	
	
	$('.carousel').slick({
	speed: 600, //スライドのスピード（初期値：300）
	autoplay: true, //オート切り替え（初期値：false）
	autoplaySpeed: 3000, //オート切り替えのスピード（初期値：3000）
	dots: true, //黒ボッチ（ナビ）の表示（初期値：false）
	//easing: "ease",
	swipe: false, //スワイプを有効にする（初期値：true）
	touchMove: false, //スワイプ中のスライダーの移動を有効にする（初期値：true）
	arrows: true, //矢印（ナビ）の有無（初期値：true）
	slidesToShow: 1, //表示する要素の数値（初期値：1）
	slidesToScroll: 1, //スライドで動かす要素の数値（初期値：1）
	infinite: true, //スライドループの有無（初期値：true）
	});
	
	
	/* addClass
	/* ------------------------------------ */
			jQuery("#accordion li:first-child").addClass("default open");
	
	
	
	/*  iframe-content
	/* ------------------------------------ */
		$(".entry-inner iframe").wrap("<div class=\"iframe-content\" />");
	
	
	/*  Scroll to top
	/* ------------------------------------ */
	 var showFlag = false;
		var topBtn = $('#page-top');
		topBtn.css('bottom', '-100px');
		var showFlag = false;
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				if (showFlag == false) {
					showFlag = true;
					topBtn.stop().animate({'bottom' : '20px'}, 200);
				}
			} else {
				if (showFlag) {
					showFlag = false;
					topBtn.stop().animate({'bottom' : '-100px'}, 200);
				}
			}
		});
		topBtn.click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 500);
			return false;
		});
	
	
	
	
	/*  Toggle header search
	/* ------------------------------------ */
		$('.toggle-search').click(function(){
			$('.toggle-search').toggleClass('active');
			$('.search-expand').fadeToggle(250);
				setTimeout(function(){
					$('.search-expand input').focus();
				}, 300);
		});
	
	
	
	
	
	/*  Comments / pingbacks tabs
	/* ------------------------------------ */
		$(".comment-tabs li").click(function() {
			$(".comment-tabs li").removeClass('active');
			$(this).addClass("active");
			$(".comment-tab").hide();
			var selected_tab = $(this).find("a").attr("href");
			$(selected_tab).fadeIn();
			return false;
		});
	
	/*  Table odd row class
	/* ------------------------------------ */
		$('table tr:odd').addClass('alt');
	
	
	/*  Dropdown menu animation
	/* ------------------------------------ */
		$('.nav ul.sub-menu').css('opacity', '0');
		$('.nav > li').hover(
	
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"1.0","margin-top":"-3px"})
			},
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"0"})
			}
		);
			$('.nav ul > li').hover(
	
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"0.95","top":"0px"})
			},
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"0","top":"0px"})
			}
		);
	
	
	
			$('#nav-footer .nav > li').hover(
	
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"1.0","bottom":"40px"})
			},
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"0","bottom":"36px"})
			}
		);
			$('#nav-footer .nav ul > li').hover(
	
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"1","bottom":"0px"})
			},
			function() {
				$(this).children('ul.sub-menu').css({"opacity":"0","bottom":"36px"})
			}
		);
	
	
	/* ------------------------------------ */
	
		});
	
	
	
	